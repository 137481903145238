<script setup lang="ts">
const route = useRoute()

const active = ref(true)

onMounted(() => {
//   setTimeout(() => {
//     active.value = true
//   }, 1000)
})
</script>

<template>
  <div class="com-primary-navigation">
    <transition name="nav" appear>
      <div v-if="active" key="navigation" class="side-navigation">
        <figure class="menu-item" :class="{ active: route.name === 'index' }" @click="$router.push('/')">
          <div class="icon">
            <Icon icon="session" />
          </div>
          <div class="label">
            <span>Home</span>
          </div>
        </figure>
        <figure class="menu-item" :class="{ active: route.name === 'sessions' }" @click="$router.push('/sessions')">
          <div class="icon">
            <Icon icon="sessions" />
          </div>
          <div class="label">
            <span>Sessions</span>
          </div>
        </figure>
        <figure class="menu-item" :class="{ active: route.name === 'values' }" @click="$router.push('/values')">
          <div class="icon">
            <Icon icon="values" />
          </div>
          <div class="label">
            <span>My Values</span>
          </div>
        </figure>
        <figure class="menu-item" :class="{ active: route.name === 'record' }" @click="$router.push('/record')">
          <div class="icon">
            <Icon icon="record" />
          </div>
          <div class="label">
            <span>My Record</span>
          </div>
        </figure>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
  @import '@/assets/scss/global.scss';

  .nav-enter {
    opacity: 0;
    transform: translateX(-100%);
  }

  .nav-enter-to {
    opacity: 1;
    transform: translateX(0);
  }

  .nav-enter-active {
    transition: all 0.3s ease;
  }

  .nav-leave {
    opacity: 0;
    transform: translateX(0%);
  }

  .nav-leave-to {
    opacity: 1;
    transform: translateX(-100%);
  }

  .nav-leave-active {
    transition: all 0.3s ease;
  }

  .com-primary-navigation {
    background: #f8f8f8;
    position: relative;
    height: 100%;
    z-index: 3;

    .side-navigation {
      position: absolute;
      top: 0;
      z-index: 8;
      box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
      border-radius: 0 0.75rem 0.75rem 0;
      height: 100%;
      background: transparent;
      padding-top: 0;
      padding-bottom: 10px;
      left: 0;
      width: 120px;
      overflow: hidden;

      figure.menu-item {
        width: 100%;
        height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease;
        border-bottom: 1px solid #f7f7f7;

        * {
          transition: all 0.175s ease-in-out;
        }

        > .icon {
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 36px;
            height: 36px;
            transition: all 0s ease-in-out;
            fill: #5d78af;
            stroke: transparent;

            * {
              stroke: transparent;
            }
          }
        }

        > .label {
          text-align: center;

          span {
            margin: 0;
            text-transform: uppercase;
            font-weight: 500;
            color: #5d78af;
            @apply text-sm;
          }
        }

        &:hover {
          background: #f7f7f7;

          .icon {
            svg {
              width: 36px;
              height: 36px;
              @apply fill-current;
              @apply text-blue-500;
            }
          }

          .label {
            span {}
          }
        }
      }

      figure.menu-item.active {

        background: #f7f7f7;

        &:after {
          content: ' ';
          background: #0577f0;
          border-radius: 10px;
          position: absolute;
          width: 6px;
          height: 90px;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }


        .label {
          span {
            @apply text-blue-600;
          }
        }

        .icon {
          svg {
            width: 38px;
            height: 38px;
            @apply fill-current;
            @apply text-blue-500;
          }
        }
      }
    }
  }
</style>