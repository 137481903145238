<script setup lang="ts">
const route = useRoute()
</script>

<template>
  <div class="com-dash-footer">
    <transition name="df" appear>
      <div class="dash-footer-navigation">
        <div class="nav-items">
          <DashNavItem :active="route.name === '/'" link="/" label="Home" icon="home" />
          <DashNavItem :active="route.name === 'sessions'" link="/sessions" label="Sessions" icon="sessions" />
          <DashNavItem :active="route.name === 'values'" link="/values" label="My Values" icon="values" />
          <DashNavItem :active="route.name === 'record'" link="/record" label="My DMO" icon="record" />
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.com-dash-footer {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    .dash-footer-navigation {

        position: absolute;
        width: 100%;
        //height: 82px;
        border-radius: 26px 26px 0 0;
        background: #ffffff;
        box-shadow: 0px -3px 20px rgba(45, 45, 45, 0.141444);

        max-width: 600px;
        margin: 0 auto;

        min-height: 60px;

        .nav-items {
            display: flex;
            justify-content: space-around;
            max-width: 90%;
            margin: 0 auto;
        }
    }

    .df-enter {
        transform: translateY(100%);
    }

    .df-enter-to {
        transform: translateY(0);
    }

    .df-enter-active {
        transition: all 0.3s ease;
        transition-delay: 0.3s;
    }

    .df-enter-leave {
        transition: all 0.3s ease;
        transition-delay: 0.6s;
    }

    .df-leave {
        transform: translateY(0);
    }

    .df-leave-to {
        transform: translateY(100%);
    }
}
</style>
