<script setup lang="ts">
import { useAppStore } from '@/stores/app'

const { ww } = useWindow()

const { activeDeviceMedia } = useEnvironment()

const { uiAvailable } = useDMOSession()

const $app = useAppStore()

const uiDebounce = ref(false)

watch(activeDeviceMedia, (device) => {
  if (device) {
    setTimeout(() => {
      uiDebounce.value = true
    }, 500)
  }
}, {
  immediate: true,
})

useHead({
  script: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/lottie-player/1.4.3/lottie-player.min.js',
      type: 'text/javascript',
      defer: true,
    },
    {
      src: 'https://fast.wistia.com/assets/external/E-v1.js',
      async: true,
      defer: true,
    },
  ],
})

onMounted(() => {
  $app.sessionState = 'dashboard-ready'
})
</script>

<template>
  <main class="canvas-full layout-dashboard">

    <!-- <div class="sec-debug">
      {{ $app.sessionState }}
    </div> -->
    <div id="mpdmodal" />


    <Transition name="loading" mode="out-in">
      <div v-if="!uiAvailable" key="loading" class="sec-loading">
        <span class="loader">
          <Spinner :active="true" color="#00ABE9" size="md" />
        </span>
      </div>
    </Transition>

    <LayoutDashboardMobile v-if="uiDebounce && activeDeviceMedia === 'mobile'" key="dashboard-mobile">
      <slot />
    </LayoutDashboardMobile>
    <LayoutDashboardDesktop v-else-if="uiDebounce && activeDeviceMedia === 'desktop'" key="dashboard-desktop">
      <slot />
    </LayoutDashboardDesktop>
  </main>
</template>

<style lang="scss">
@import '@/assets/scss/components/tippy.scss';

.sec-debug {
  position: fixed;
  top:0;
  left:0;
  background:red;
  color:white;
  z-index: 10;
}

.loading-enter-active,
.loading-leave-active {
  transition: all 0.2s ease;
}

.loading-leave {
  opacity: 1;
}

.loading-leave-to {
  opacity: 0;
}

.layout-dashboard {
  position: relative;

  .sec-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background: white;

    display: flex;
    align-items: center;
    justify-content: center;

    >.loader {}
  }

  .page {
    height:100%;

    transition: all 0.2s ease;

    &.dash-scroll-setup {

      display:flex;
      flex-direction:column;
      .sec-content-container {
        position: relative;
        flex:1;
        overflow:auto;

        >.inner {
          height:100%;

          .scroll-container {

          }
        }
      }
    }
  }

}
</style>
