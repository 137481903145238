<script setup lang="ts">
import { resolveComponent } from 'vue'

interface DashNavItemProps {
  icon: string
  active: boolean
  label: string
  link: string
  type?: string
}

const props = withDefaults(defineProps<DashNavItemProps>(), {
  icon: 'sessions',
  active: false,
  label: '',
  link: '',
  type: '',
})

const icons: Record<string, ReturnType<typeof resolveComponent>> = {
  home: resolveComponent('NavHome'),
  sessions: resolveComponent('NavSessions'),
  values: resolveComponent('NavValues'),
  record: resolveComponent('NavRecord'),
}
</script>

<template>
  <NuxtLink class="link footer-nav-link" :to="link">
    <div class="tab_nav_item" :class="[{ active }, type]">
      <div class="icon">
        <component :is="icons[icon]" />
      </div>
      <div class="label">
        <p>{{ label }}</p>
      </div>
    </div>
  </NuxtLink>
</template>

<style lang="scss">
.link {

  &.footer-nav-link {
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

.tab_nav_item {
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  //background: green;
  padding: 0.8em 0 0.6em 0;
  min-width: 60px;

  >.icon {
    width: 23px;
    height: 23px;
    transition: all 0.2s ease;

    svg {
      width: 100%;
      height: 100%;

      @apply fill-current;
      @apply text-gray-800;
    }
  }

  >.label {
    margin-top: 1px;
    text-decoration: none !important;

    p {
      font-size: 10px;
      margin-bottom: 0;
      font-weight: bold;
      color: #333333;
      transition: all 0.2s ease;
      line-height: 1.4;
      @apply text-gray-900;
      @apply font-medium;
      text-decoration: none !important;
    }
  }

  &:hover {
    >.icon {
      svg {
        fill: #0577f0;
      }
    }

    >.label {
      p {
        color: #0577f0;
      }
    }
  }

  &:visited {
    >div {
      text-decoration: none;
    }
  }
}

.tab_nav_item.active {
  >.icon {
    svg {
      fill: #0577f0;
    }
  }

  >.label {
    p {
      color: #0577f0;
    }
  }
}

.tab_nav_item.focus {
  >.icon {
    svg {
      fill: #0577f0;
    }
  }

  >.label {
    p {
      color: #0577f0;
    }
  }
}

.tab_nav_item.continue {
  >.icon {
    * {
      fill: #2dce89;
      stroke: #2dce89;
    }
  }

  >.label {
    p {
      color: #2dce89;
    }
  }
}
</style>
