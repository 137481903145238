<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

import { useAuthStore } from '@/stores/auth'

import { useModalStore } from '@/stores/modal'

const $auth = useAuthStore()

const $modal = useModalStore()

const router = useRouter()
const fullName = computed(() => `${$auth.user.first_name} ${$auth.user.surname}`)

const isMounted = useMounted()

function logout() {
  $modal.open('loading')
  setTimeout(() => {
    $auth.logout()
  })
}
</script>

<template>
  <div class="com-header-component">
    <div class="content-wrap">
      <section class="sec-logo">
        <DemartiniLogo />
      </section>

      <section class="sec-widgets" />

      <section class="sec-account">
        <div class="sec-menu-button">
          <Menu>
            <MenuButton>
              <LetterAvatar :text="fullName" />
            </MenuButton>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems class="sec-menu-items">
                <MenuItem>
                  <button @click="logout">
                    Logout
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss">
.com-header-component {
  height: 100%;
  width: 100%;
  background: white;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);

  >.content-wrap {
    max-width: 92%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;

    >section {
      display: flex;
      align-items: center;
      height: 100%;

      &.sec-logo {
        svg {
          width: 180px;
        }
      }
    }

    >.sec-logo {}

    >.sec-account {
      .mpd-avatar {
        cursor: pointer;
      }

      >.sec-menu-button {

        position: relative;

        >button {
          width: 30px;
          height: 30px;

          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;

          transition: all 0s ease;

          svg {
            width: 20px;
            transition: all 0.1s ease;
            fill: white;
          }

          &:hover {
            //transform: scale(1.02)
            //transform: translateY(-1px)
          }

          &:active,
          &:focus {
            transform: translateY(1px);
          }
        }

        .sec-menu-items {
          position: absolute;
          background-color: #fff;
          transition: all 0s ease;
          z-index: 20;
          margin: 0;
          padding: 4px 0;
          text-align: left;
          list-style-type: none;
          background-clip: padding-box;
          border-radius: 4px;
          outline: none;
          box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
          right: 0;

          display: flex;
          flex-direction: column;

          button {
            padding: 0.6rem 1.5rem;
            font-weight: 400;
            font-size: 13px;
            line-height: 22px;
            white-space: nowrap;
            cursor: pointer;
            background: white;
            color: #4b5563;

            position: relative;

            &:hover {
              background: #f5f5f5;
              color: #022f6b;

              transform: translateY(-1px);

              color: #022f6b;

              &:active {
                transform: translateY(0);
              }
            }

          }
        }
      }
    }
  }
}
</style>
