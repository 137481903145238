<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'

import { useAppStore } from '@/stores/app'

const { initialised, layout } = useEnvironment()

const $app = useAppStore()

const router = useRouter()

const $auth = useAuthStore()

function processBack() {
  router.push('/')
}

// Debug
const footer = 'dashboard'
const pageLabel = 'Dashboard'
</script>

<template>
  <div v-if="initialised" class="layout-dashboard-desktop layout-child">
    <section class="sec-header" :class="{ active: layout.header === 'dashboard' }">
      <HeaderComponent v-if="layout.header === 'dashboard'" />
    </section>
    <section class="sec-primary">
      <section class="sec-modules-navigation" :class="{ active: layout.navigation }">
        <PrimaryNavigation v-if="layout.navigation" />
      </section>
      <section class="sec-primary-container">
        <div class="layer background">
          <section class="sec-bg-header" :class="{
            active: layout.header === 'create' || layout.header === 'complete',
          }">
            <div class="bg-layer light-grad" />
            <div class="bg-layer texture" />
          </section>

          <div class="sec-bg-content" />
        </div>

        <div class="layer content">
          <div class="sec-content-layer-container">
            <div class="sec-flyout" :class="{ active: layout.createFlyout }" />
            <div class="sec-content">
              <!-- <transition-fade mode="out-in"> -->
              <div class="sec-header-container"
                :class="{ active: layout.header === 'create' || layout.header === 'complete' }">
                <div v-if="layout.header === 'create'" key="create" class="sec-header-content create">
                  <div class="content-wrap">
                    <div class="sec-back-button">
                      <Transition name="back" appear>
                        <div key="back" class="action" @click="processBack">
                          <Icon icon="back" />
                        </div>
                      </Transition>
                    </div>

                    <div class="sec-content">
                      <div class="sec-title">
                        <h3>New Session</h3>
                      </div>
                      <div class="sec-branding">
                        <div class="logo">
                          <DemartiniLogo />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="layout.header === 'complete'" key="complete" class="sec-header-content complete">
                  <div class="content-wrap">
                    <div class="sec-content">
                      <div class="sec-title">
                        <div class="big-title">
                          <h3>Congratulations {{ $auth.user.first_name }}</h3>
                        </div>

                        <div class="sub-title">
                          <div>Well done for breaking through!</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- </transition-fade> -->


              <div class="sec-primary-content">
                <div class="bg-texture" />
                <ContentWrapper>
                  <NuxtPage :key="String($route.name)" />
                </ContentWrapper>
              </div>

              <div v-if="false" class="sec-content-footer">
                <!-- layout.footer -->
                <Transition name="footercontent" mode="out-in">
                  <div v-if="footer === 'dashboard'" key="dashboard" class="content-wrap" :class="footer">
                    <div class="sec-quote">
                      <div>
                        A systematic pre-determined series of
                        <strong>mental questions</strong> directed toward the objective of
                        assisting an individual
                        <strong>to feel present, certainty</strong> and
                        <strong>gratitude</strong>.
                      </div>
                    </div>
                    <div class="sec-breadcrumb">
                      <div>/ {{ pageLabel }}</div>
                    </div>
                  </div>

                  <div v-else-if="footer === 'create'" key="create" class="content-wrap" :class="footer">
                    <div class="sec-quote">
                      <div>Create a new session.</div>
                    </div>
                    <div class="sec-breadcrumb">
                      <div>/ {{ pageLabel }}</div>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <div class="sec-version">
      {{ $app.version }}
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/global.scss";

// HEADER SLIDE DOWN
.slide-down-enter {
  transform: translateY(-100%);
}

.slide-down-enter-to {
  transform: translateY(0);
}

.slide-down-enter-active {
  transition: transform 0.3s ease-out;
}

.slide-down-leave {
  transform: translateY(0);
}

.slide-down-leave-to {
  transform: translateY(-100%);
}

.slide-down-leave-active {
  transition: transform 0.3s ease-out;
}

.footercontent-enter {
  opacity: 0;
  //transform: translateY(100%)
}

.footercontent-enter-to {
  opacity: 1;
  //transform: translateY(0)
}

.footercontent-enter-active {
  transition: all 1s ease-in-out;
  transition-delay: 0.5s;
}

.footercontent-leave-active {
  transition: all 0.1s ease-in-out;
}

.footercontent-leave {
  opacity: 1;
  //transform: translateY(0)
}

.footercontent-leave-to {
  opacity: 0;
  //transform: translateY(100%)
}

.layout-dashboard-desktop {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;

  overflow: hidden;

  display: flex;
  flex-direction: column;

  .sec-version {
    position: absolute;
    bottom: 10px;
    right: 30px;
    font-size: 13px;
    color: #888888;
    z-index: 10;
  }

  // Top level
  .sec-header {
    transition: all 0.3s ease;
    height: 0px;
    position: relative;
    //z-index: 6;
    z-index: 6;

    &.active {
      height: 70px;
    }
  }

  .sec-primary {
    //background: purple;
    flex: 1;

    display: flex;
    flex-direction: row;
    position: relative;


    .sec-modules-navigation {
      transition: all 0.3s ease;

      width: 0;
      // background: green;
      position: relative;
      //z-index: 8;

      &.active {
        width: 120px;
      }

      // &.none {
      //   width: 0;
      // }
      // &.mini {
      //   width: 80px;
      // }
      // &.normal {
      //   width: 300px;
      // }
      // &.manage {
      //   width: 440px;
      // }
      // &.flyout {
      //   width: 480px;
      // }
      // &.expand {
      //   width: 800px;
      // }
    }

    .sec-primary-container {
      flex: 1;

      //background: gold;
      transition: all 0.3s ease;

      position: relative;

      //z-index: 8;

      >.layer {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        transition: all 0.3s ease;

        &.background {
          background-color: #f8f8f8;

          .sec-bg-header {
            height: 0;
            transition: all 0.3s ease;

            position: relative;

            /* Permalink - use to edit and share this gradient:
https://colorzilla.com/gradient-editor/#031953+0,017bc5+100 */
            background: rgb(3, 25, 83);
            /* Old browsers */
            background: -moz-linear-gradient(left,
                rgba(3, 25, 83, 1) 0%,
                rgba(1, 123, 197, 1) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(left,
                rgba(3, 25, 83, 1) 0%,
                rgba(1, 123, 197, 1) 100%);
            /*
Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,
                rgba(3, 25, 83, 1) 0%,
                rgba(1, 123, 197, 1) 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
*/
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#031953',
                endColorstr='#017bc5', GradientType=1);
            /* IE6-9 */

            >.bg-layer {
              position: absolute;
              width: 100%;
              height: 100%;

              &.light-grad {
                background-image: url("/img/light-grad-one.png");
                background-size: cover;
                mix-blend-mode: overlay;
              }

              &.texture {
                background-image: url("/img/texture-dots.png");
                mix-blend-mode: screen;
                background-size: 70%;
                background-position: right bottom;
                background-repeat: no-repeat;
                opacity: 0.9;
              }
            }

            &.active {
              height: 325px;
            }


          }

          .sec-bg-content {}
        }

        &.content {
          z-index: 2;

          .sec-content-layer-container {
            width: 100%;
            height: 100%;
            display: flex;

            >div {
              height: 100%;
            }

            >.sec-flyout {
              width: 0;
              transition: all 0.3s ease;

              &.active {
                width: 300px;
              }
            }

            >.sec-content {
              flex: 1;
              display: flex;
              flex-direction: column;

              >.sec-header-container {
                height: 0px;
                transition: all 0.3s ease;

                &.active {
                  height: 20%;
                }

                >.sec-header-content {
                  height: 100%;
                  transition: all 0.3s ease;

                  .content-wrap {

                    position: relative;

                    //border:1px solid red;
                    max-width: 90%;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    height: 100%;

                    border-bottom: 1px solid white;

                    padding-bottom: 1.5rem;

                    .sec-content {
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;

                      .sec-title {
                        h3 {
                          color: white;
                          //font-size:34.92px;
                          font-size: 30.92px;
                          font-weight: 700;

                          margin: 0 !important;
                        }
                      }

                      .sec-branding {
                        .logo {
                          svg {
                            width: 230px;
                            fill: white;

                            * {
                              fill: white;
                            }
                          }
                        }
                      }
                    }
                  }

                  &.complete {
                    // min-height: 29%;
                    height: 220px;

                    >.content-wrap {
                      border-bottom: 0;
                      display: flex;
                      justify-content: center;

                      >.sec-content {
                        width: auto;

                        >.sec-title {
                          display: flex;
                          flex-direction: column;

                          >.big-title {
                            padding-bottom: 3px;
                            text-align: center;

                            h3 {
                              text-align: center;
                              font-size: 42px;
                              font-weight: 900;
                            }
                          }

                          >.sub-title {
                            div {
                              text-align: center;
                              font-weight: 200;
                              color: white;
                              font-size: 26px;
                            }
                          }
                        }
                      }
                    }
                  }

                  &.create {
                    >.content-wrap {

                      .sec-back-button {

                        height: 80px;

                        width: 80px;
                        position: absolute;

                        top: 0;
                        left: 5px;
                        top: calc(50% - 20px);
                        transform: translateY(-50%);

                        align-items: center;
                        justify-content: flex-start;

                        @include mq($from: d) {
                          display: flex;
                        }

                        >.action {

                          cursor: pointer;

                          svg {
                            //fill: #50b7ec;
                            fill: white;
                            width: 13px;
                            transition: all 0.15s ease;
                          }



                          &:hover {
                            svg {
                              transform: scale(1.08);
                            }
                          }

                          &:focus,
                          &:active {
                            transform: translateY(1px);
                          }
                        }
                      }
                    }
                  }
                }
              }

              >.sec-primary-content {
                flex: 1;
                transition: all 0.3s ease;
                position: relative;
                overflow: auto;

                >.bg-texture {
                  background-image: url("/img/texture-dots.png");
                  background-position: right bottom;
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-size: 65%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  mix-blend-mode: screen;
                  opacity: 0.7;
                }

                //mix-blend-mode: screen;
              }

              >.sec-content-footer {
                height: 70px;

                >.content-wrap {
                  width: 90%;
                  margin: 0 auto;
                  display: flex;
                  justify-content: space-between;
                  height: 100%;
                  border-top: 1px solid #e7e7e7;

                  >div {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    * {
                      color: $body-primary;
                    }
                  }

                  >.sec-quote {
                    flex: 1;

                    >div {
                      strong {
                        color: $light-teal;
                        font-weight: 900;
                      }
                    }
                  }

                  >.sec-breadcrumb {
                    width: auto;
                    padding-left: 3rem;

                    >div {
                      font-weight: 900;
                      color: $dark-blue;
                    }
                  }
                }
              }
            }
          }
        }
      }

    }

  }
}
</style>
