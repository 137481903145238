<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

import { useAuthStore } from '@/stores/auth'

import { useSessionPrepStore } from '@/stores/sessionPrep'

const router = useRouter()

const { initialised, layout } = useEnvironment()

const $sessionPrep = useSessionPrepStore()

const $auth = useAuthStore()

const backActive = ref(false)
const menuActive = ref(false)

const route = useRoute()

const routeName = computed(() => route.name)

watch(routeName, (rn) => {
  if (rn === 'create') {
    backActive.value = true
    menuActive.value = false
  }
  else {
    backActive.value = false
    menuActive.value = true
  }
}, {
  immediate: true,
})

// const fullName = computed(() => `${$auth.user.first_name} ${$auth.user.surname}`)
const firstName = computed(() => $auth.user.first_name)

function processBack() {
  router.push('/')
}

function logout() {
  $auth.logout()
}
</script>

<template>
  <div class="layout-child layout-dashboard-mobile layout-mobile sec-primary-container" :class="route.name">
    <div class="layer background">
      <section class="sec-bg-header">
        <div class="bg-layer light-grad" />
        <div class="bg-layer texture" />
      </section>
    </div>
    <div class="layer content" :class="route.name">
      <div class="sec-header" :class="{ active: layout.header }">
        <Transition name="mobileheadertitle" mode="out-in">
          <div v-if="route.name === 'index'" key="index" class="content-wrap sec-header-title" :class="route.name">
            <div class="subtitle">
              Welcome to the
            </div>
            <div class="title">
              Demartini Method Online
            </div>
          </div>
          <div
            v-else-if="route.name === 'sessions'" key="sessions" class="content-wrap sec-header-title"
            :class="route.name"
          >
            <div class="title">
              My Sessions
            </div>
          </div>
          <div v-else-if="route.name === 'values'" key="values" class="content-wrap sec-header-title" :class="route.name">
            <div class="title">
              My Values
            </div>
          </div>
          <div v-else-if="route.name === 'record'" key="record" class="content-wrap sec-header-title" :class="route.name">
            <div class="title">
              My Record
            </div>
          </div>
          <div
            v-else-if="route.name === 'create'" key="newsession" class="content-wrap sec-header-title"
            :class="route.name"
          >
            <div class="title">
              New Session
            </div>
          </div>

          <div
            v-else-if="route.name === 'session-complete'" key="sessioncomplete" class="content-wrap sec-header-title"
            :class="route.name"
          >
            <div class="title">
              Congratulations {{ firstName }}
            </div>
            <div class="subtitle">
              Well done for breaking through!
            </div>
          </div>
        </Transition>

        <transition-slide :offset="[0, -16]">
          <div v-if="backActive" key="back" class="sec-back-button">
            <button key="back" class="action" @click="processBack">
              <i class="i-mdi:chevron-left" />
            </button>
          </div>
        </transition-slide>

        <!-- <Transition name="secmenu" appear mode="out-in"> -->
        <transition-slide :offset="[0, -16]">
          <div v-if="menuActive" key="menu" class="sec-menu">
            <div class="sec-menu-button">
              <Menu>
                <MenuButton>
                  <Icon icon="menu" />
                </MenuButton>
                <Transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <MenuItems class="sec-menu-items">
                    <MenuItem>
                      <button @click="logout">
                        Logout
                      </button>
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
            </div>
          </div>
        </transition-slide>
        <!-- </Transition> -->
      </div>
      <div class="sec-primary">
        <div class="sec-mobile-container">
          <!-- <transition-slide :offset="[0, -16]" :appear="true"> -->
          <slot :key="route.name" />
          <!-- </transition-slide> -->
        </div>
      </div>
      <div v-if="route.name !== 'create'" class="sec-footer" :class="{ active: layout.footer }">
        <MobileNavigation :active="layout.footer" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/mq.scss";

.layout-mobile {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.layout-dashboard-mobile {
  // background: purple;
}

.mobileheadertitle-enter {
  opacity: 0;
  transform: translateY(8%) !important;
}

.mobileheadertitle-enter-to {
  opacity: 1;
  transform: translateY(8%) !important;
}

.mobileheadertitle-enter-active {
  transition: all 0.3s ease;
  transition-delay: 100ms;
}

.mobileheadertitle-leave {
  opacity: 1;
  transform: translateY(8%) !important;
}

.mobileheadertitle-leave-to {
  opacity: 0;
  transform: translateY(-50%) !important;
}

.mobileheadertitle-leave-active {
  transition: all 0.3s ease;
  transition-delay: 100ms;
}

.secback-enter {
  opacity: 0;
  transform: translateY(8%) !important;
}

.secback-enter-to {
  opacity: 1;
  transform: translateY(8%) !important;
}

.secback-enter-active {
  transition: all 0.3s ease;
  transition-delay: 100ms;
}

.secback-leave {
  opacity: 1;
  transform: translateY(8%) !important;
}

.secback-leave-to {
  opacity: 0;
  transform: translateY(-50%) !important;
}

.secback-leave-active {
  transition: all 0.3s ease;
  transition-delay: 100ms;
}

.secmenu-enter {
  opacity: 0;
  transform: translateY(8%) !important;
}

.secmenu-enter-to {
  opacity: 1;
  transform: translateY(8%) !important;
}

.secmenu-enter-active {
  transition: all 0.3s ease;
  transition-delay: 100ms;
}

.secmenu-leave {
  opacity: 1;
  transform: translateY(8%) !important;
}

.secmenu-leave-to {
  opacity: 0;
  transform: translateY(-50%) !important;
}

.secmenu-leave-active {
  transition: all 0.3s ease;
  transition-delay: 100ms;
}

.layout-dashboard-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;

  &.sec-primary-container {
    position: relative;
    height: 100%;
    width: 100%;

    > .layer {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all 0.3s ease;

      &.background {
        height: 20vh;

        .sec-bg-header {
          height: 0;
          transition: all 0.3s ease;
          position: relative;
          height: 100%;

         

          /* Permalink - use to edit and share this gradient:
https://colorzilla.com/gradient-editor/#031953+0,017bc5+100 */
          background: rgb(3, 25, 83); /* Old browsers */
          background: -moz-linear-gradient(
            left,
            rgba(3, 25, 83, 1) 0%,
            rgba(1, 123, 197, 1) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            left,
            rgba(3, 25, 83, 1) 0%,
            rgba(1, 123, 197, 1) 100%
          ); /* Chrome10-25, Safari5.1-6 */
          background: linear-gradient(
            to right,
            rgba(3, 25, 83, 1) 0%,
            rgba(1, 123, 197, 1) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#031953', endColorstr='#017bc5', GradientType=1); /* IE6-9 */
        
          > .bg-layer {
            position: absolute;
            width: 100%;
            height: 100%;
            transition: all 0.3s ease;

            &.light-grad {
              background-image: url("/img/light-grad-one.png");
              background-size: cover;
              mix-blend-mode: overlay;
            }

            &.texture {
              background-image: url("/img/texture-dots.png");
              mix-blend-mode: screen;
              background-size: 100%;
              background-position: right bottom;
              background-repeat: no-repeat;
              opacity: 0.9;
            }
          }

          &.active {
            height: 325px;
          }
        }
      }

      &.content {
        z-index: 1;
        display: flex;
        flex-direction: column;

        .sec-header {
          height: 16.5vh;
          transition: all 0.3s ease;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          &.active {
            height: 100px;
          }

          > .sec-header-title {
            transform: translateY(-100%);
          }

          > .content-wrap {
            display: flex;
            flex-direction: column;
            transform: translateY(8%);
            gap: 0.2rem 0;

            * {
              text-align: center;
              color: white;
            }

            @include mq($from: t, $until: d) {
              gap: 0.4rem 0;
            }

            > div {
              &.subtitle {
                font-size: 13px;

                @include mq($from: txs, $until: t) {
                  font-size: 16px;
                }

                @include mq($from: t, $until: d) {
                  font-size: 17px;
                }
              }

              &.title {
                font-size: 20px;

                @include mq($from: txs, $until: t) {
                  font-size: 22px;
                }

                @include mq($from: t, $until: d) {
                  font-size: 25px;
                }
              }
            }
          }

          > .sec-back-button {
            top: 20px;
            left: 20px;
            position: absolute;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            > .action {
              width: 100%;
              height: 100%;
              cursor: pointer;

              i {
                color: white;
                height: 16px;
                transition: all 0.15s ease;
                display: inline-block;
                width: 100%;
                height: 100%;
              }

              &:hover {
                i {
                  transform: scale(1.08);
                }
              }

              &:focus,
              &:active {
                transform: translateY(1px);
              }
            }
          }

          > .sec-menu {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 30px;

            > .sec-menu-button {
              position: relative;

              > button {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all 0s ease;

                svg {
                  width: 20px;
                  transition: all 0.1s ease;
                  fill: white;
                }

                &:active,
                &:focus {
                  transform: translateY(1px);
                }
              }

              .sec-menu-items {
                position: absolute;
                background-color: #fff;
                transition: all 0s ease;
                z-index: 20;
                margin: 0;
                padding: 4px 0;
                text-align: left;
                list-style-type: none;
                background-clip: padding-box;
                border-radius: 4px;
                outline: none;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
                right: 0;
                display: flex;
                flex-direction: column;

                button {
                  padding: 0.6rem 1.5rem;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 22px;
                  white-space: nowrap;
                  cursor: pointer;
                  background: white;
                  color: #4b5563;
                  position: relative;

                  &:hover {
                    background: #f5f5f5;
                    color: #022f6b;
                    transform: translateY(-1px);

                    &:active {
                      transform: translateY(0);
                    }
                  }
                }
              }
            }
          }
        }

        .sec-primary {
          flex: 1;
          position: relative;
          transition: all 0.3s ease;

          > .sec-mobile-container {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            overflow: auto;
          }
        }

        .sec-footer {
          transition: height 0.3s ease;
          position: relative;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0px;

          .com-dash-footer {
            height: 80px;
            transition: all 0.3s ease;
            position: absolute;
            z-index: 10;
            bottom: 0;
          }

          &.active {
            .com-dash-footer {
              transform: translateY(0%);
            }
          }
        }
      }
    }

    &.index {
      > .layer {
        &.background {
          height: 20vh;
        }

        &.content {
          .sec-header {
            height: 12.5vh;
            align-items: flex-end;

            transition: all 0.3s ease;

            @include mq($until: txs) {
              margin-bottom: 1rem;
            }
          }

          .sec-primary {
            border-radius: 18px;
            max-width: 100%;
            padding: 1rem 0.25rem;

          }
        }
      }
    }

    &.create,
    &.sessions,
    &.values {
      > .layer {
        &.background {
          height: 140px;
        }

        &.content {
          .sec-header {
            height: 120px;
          }
        }
      }
    }

    &.values {
      > .layer {
        &.background {
          height: 140px;
        }

        &.content {
          .sec-header {
            height: 140px;
          }
        }
      }
    }

    &.session-complete {
      > .layer {
        &.content {
          .sec-header {
            height: 110px;

            .sec-header-title {
              .title {
                @include mq($until: t) {
                  font-size: 22px;
                }
              }

              .subtitle {
                @include mq($until: t) {
                  font-size: 15px;
                }
              }
            }
          }

          .sec-primary {
            .sec-mobile-container {
              padding-bottom: 10rem;
            }
          }
        }
      }
    }
  }
}
</style>
