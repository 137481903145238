<script setup lang="ts">
interface LetterAvatarProps {
  text?: string
}

const props = withDefaults(defineProps<LetterAvatarProps>(), {
  text: '',
})

const result = ref<string>('')

function processText(text: string) {
  if (text && text.length > 0) {
    let t = props.text.replace(')', '')
    t = t.replace('(', '')

    const pieces = t.split(' ')

    if (pieces && Array.isArray(pieces) && pieces.length === 1) {
      result.value = pieces[0].slice(0, 1)
    }
    else if (pieces && Array.isArray(pieces) && pieces.length > 1) {
      result.value = ''
      const first = pieces[0]
      const last = pieces.pop()
      if (first.length > 0 && last && last.length > 0)
        result.value = first[0].slice(0, 1) + last[0].slice(0, 1)

      else
        result.value = 'SS'
    }
  }
}

const text = computed(() => props.text)

watch(text, () => {
  if (text.value && text.value.length > 0)
    processText(text.value)
}, {
  immediate: true,
})
</script>

<template>
  <div class="letter-avatar" :class="{ placeholder: !text }">
    <template v-if="text">
      <div class="inner">
        <div class="deselector" />
        <div class="avatar">
          <span>{{ result }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <svg class="placeholder" viewBox="0 0 227 229" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M69.939 118.795C28.873 135.878 0 176.385 0 223.635c0 1.484.028 2.961.085 4.431h226.83c.057-1.47.085-2.947.085-4.431 0-47.42-29.081-88.048-70.381-105.022-12.049 9.221-27.115 14.699-43.46 14.699-16.237 0-31.21-5.406-43.22-14.517Z" /><path d="M175.192 62.033c0 34.26-27.773 62.033-62.033 62.033-34.26 0-62.033-27.773-62.033-62.033C51.126 27.773 78.9 0 113.16 0c34.26 0 62.033 27.773 62.033 62.033Z" /></svg>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.letter-avatar {
  > .inner {
    position: relative;
    > .deselector {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    > .avatar {
      width: 32px;
      height: 32px;
      border-radius: 10px;
      background: #4bb4ff;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: white;
        font-weight: 500;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        font-size:14px;
      }
    }
  }
}
</style>
